















































import { Ciclo,GuardarCiclo } from '@/entidades/Plantacion/Ciclo';
import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';
import HaciendaItemLote from '@/entidades/Plantacion/HaciendaItemLote';
import Labor from '@/entidades/Plantacion/Labor';
import {Vue,Component,Prop} from 'vue-property-decorator';
import {State,Getter} from 'vuex-class';
@Component
export default class FormularioCiclo extends Vue
{
    @Prop({type:Array,required:true})
    listadoHacienda!:Array<HaciendaItem>

    haciendaId:number=0;
    loteId:number=0;
    laborId:number=0
    listadoLote:Array<HaciendaItemLote> |undefined =[];

    listadoLabores:Array<Labor> = [];
    cantidad:number =0;

    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    obtenerLote()
    {
        var rsp =  this.listadoHacienda.find(c=>c.haciendaId == this.haciendaId);
        if(rsp != undefined)
        {
            rsp.lotes.forEach(t=> {
                //@ts-ignore
                this.listadoLote?.push(new HaciendaItemLote(Number(t.loteId),Number(t.hectareas),t.descripcion,0,0));
            });
        } 
    }

    async ObtenerLabores()
    {
        try
        {
            let response = await Labor.ObtenerSelect();
            if(response.isSuccess == true)
            {
                this.listadoLabores  = response.isResult;
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }
    async guardar()
    {
        try
        {
            var ciclo:Ciclo = new Ciclo(0,"",this.laborId,"",this.haciendaId,this.loteId,"","",this.cantidad,true,this.usuarioId);
            var rsp = await GuardarCiclo(ciclo);
            if(rsp.isSuccess == true)
            {
                this.$emit('limpiar');
            }
            else
            {
                console.log(rsp);
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }
    mounted() 
    {
        this.ObtenerLabores();
    }
}
