




























import { Ciclo,ClonarCiclo } from '@/entidades/Plantacion/Ciclo';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Geolocation } from '@capacitor/geolocation';
@Component
export default class extends Vue
{
    @Prop({type:Array,required:true})
    headers!:Array<any>;

    @Prop({type:Array,required:true})
    items!:Array<ClientTypes>;

    //variables
    search:string="";

    async clonar(item:Ciclo)
    {
        try
        {
            
            let response = await ClonarCiclo(item);
            console.log(response);
            if(response.isSuccess == true)
            {
                this.$emit('clonar');
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }

    get UpdateItems()
    {
        return this.items;
    }
    async mounted() 
    {
        // const position = await Geolocation.getCurrentPosition();
        
        // const latitude = position.coords.latitude;
        // const longitude = position.coords.longitude;

        // console.log(position);
        // console.log(latitude);
        // console.log(longitude);
    }
}
