


































import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';
import HaciendaItemLote from '@/entidades/Plantacion/HaciendaItemLote';
import {Vue,Component,Prop} from 'vue-property-decorator';
@Component
export default class FiltroHaciendaComponente extends Vue
{

    @Prop({type:Array,required:true})
    listadoHacienda!:Array<HaciendaItem>

    haciendaId:number=0;
    loteId:number=0;

    listadoLote:Array<HaciendaItemLote> |undefined =[];

    obtenerLote()
    {
        var rsp =  this.listadoHacienda.find(c=>c.haciendaId == this.haciendaId);
        if(rsp != undefined)
        {
            rsp.lotes.forEach(t=> {
                this.listadoLote?.push(new HaciendaItemLote(Number(t.haciendaLoteId),t.descripcion,undefined,undefined));
            });
        }
    }
    guardar()
    {
        this.$emit('guardar',this.haciendaId,this.loteId);
    }

}
