import {Save,Obtener,Editar,ObtenerPost2} from '@/Generico/Conexion';
import ResponseGenerico from '../ResponseGenerico';
import {mainAxios} from '@/plugins/axios'
class Ciclo
{
    cicloId:number =0;
    laborName:string ="";
    laborId:number =0;
    tipoLaborName:string ="";
    haciendaId:number =0;
    loteId:number =0;
    haciendaName:string ="";
    loteName:string="";
    cantidad:number=0;
    estado:boolean=true;
    usuarioId:string ="";

    constructor(_cicloId:number,_laborName:string,_laborId:number,_tipoLaborName:string,
            _haciendaId:number,_loteId:number,_haciendaName:string,_loteName:string,
            _cantidad:number,_estado:boolean,
            _usuarioId:string
        )
    {
        this.cicloId = _cicloId;
        this.laborName = _laborName;
        this.laborId = _laborId;
        this.tipoLaborName = _tipoLaborName;
        this.haciendaId = _haciendaId;
        this.loteId = _loteId;
        this.haciendaName = _haciendaName;
        this.loteName = _loteName;
        this.cantidad = _cantidad;
        this.estado = _estado;
        this.usuarioId = _usuarioId;
    }

}

class CicloLaborQuery {
  haciendaId: number=0;
  loteId: number=0;
  laborId: number=0;

  static async ValidarCiclo(request:CicloLaborQuery)
  {
    return ObtenerPost2<CicloLaborResponse,CicloLaborQuery>('ciclolabor/validarciclo',request,mainAxios);
  }
}

class CicloLaborResponse {
  cicloId: number=0;
  cantidad: number=0;
}



async function  GuardarCiclo(ciclo:Ciclo):Promise<ResponseGenerico>
{
    return await Save<Ciclo>("ciclolabor/Guardar",ciclo,mainAxios);
}
async function ObtenerCiclo()
{
    return await Obtener<Ciclo>("cicloLabor/Obtener",mainAxios);
}

async function  ClonarCiclo(ciclo:Ciclo):Promise<ResponseGenerico>
{
    return await Save<Ciclo>("ciclolabor/clonar",ciclo,mainAxios);
}

export
{
    Ciclo,
    CicloLaborQuery,
    CicloLaborResponse,
    GuardarCiclo,
    ObtenerCiclo,
    ClonarCiclo
}
