







































import {Vue,Component} from  'vue-property-decorator';
import FiltroHacienda from '@/components/Maestro/Hacienda/FiltroHaciendaLote.vue';
import FormularioCiclo from '@/components/Ciclo/Formulario.vue';
import Tabla from '@/components/Ciclo/Tabla.vue';
import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';
import {Ciclo,ObtenerCiclo} from '@/entidades/Plantacion/Ciclo';
import {Mutation} from 'vuex-class'
import Loading from '@/entidades/Sistema/Loading'
import Alerta from '@/entidades/Sistema/Alerta'

@Component({
    components:{
        FiltroHacienda,
        Tabla,
        FormularioCiclo
    }
})
export default class CicloView extends Vue
{
    @Mutation('mostrarLoading',{namespace:'sistemaModule'}) mostrarLoading!:Function;
    @Mutation('mostarAlert',{namespace:'sistemaModule'}) mostarAlert!:Function;

    listadoHacienda:Array<HaciendaItem> = [];
    listadoCiclo:Array<Ciclo> =[];
    listadoCicloGeneral:Array<Ciclo> = [];
    haciendaId:number=0;
    loteId:number=0;
    modal:boolean=false;
    currentComponent:string="";
     header:{text:string,sortable:boolean,value:string}[] =[
            {text:'Hacienda', sortable:true, value: 'haciendaName'},
            {text:'Lote', sortable:true, value: 'loteName'},
            {text:"Labor",sortable:true,value:'laborName'},
            {text:"Cantidad",sortable:true,value:'cantidad'},
            {text:'Clonar', sortable:true, value: 'clonar'},
           
    ]


    mostrarModal()
    {
        this.currentComponent = "FormularioCiclo"
        this.modal = true;
    }
    async ObtenerHaciendaItem()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            let response = await HaciendaItem.ObtenerHaciendaItem();
            if(response.isSuccess == true)
            {
                this.listadoHacienda = response.isResult;
            }
        }
        catch(error)
        {
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }finally{
            this.mostrarLoading(new Loading(false,""));
        }
    }

    async ObtenerCiclo()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            let response = await ObtenerCiclo();
            if(response.isSuccess == true)
            {
                this.listadoCiclo = response.isResult;
                this.listadoCicloGeneral = response.isResult;
            }
        }
        catch(error)
        {
            this.mostarAlert(new Alerta(error,true,"danger",2000));
        }finally{
            this.mostrarLoading(new Loading(false,""));
        }
    }
    filtrarListado(haciendaId:number,loteId:number)
    {
        console.log('entramos al metodo filtrado');
        this.listadoCiclo = this.listadoCicloGeneral.filter(c=>c.haciendaId == haciendaId && c.loteId == loteId);
    }

    limpiar()
    {
        this.ObtenerHaciendaItem();
        this.ObtenerCiclo();
        this.currentComponent = "";
        this.modal  =false;
    }

    get getPropiedades ()
    {
        if(this.currentComponent == "FormularioCiclo")
        {
            console.log('entramos en if de computed')
            return {'listadoHacienda' : this.listadoHacienda};
        }
    }
    mounted()    
    {
        this.ObtenerHaciendaItem();
        this.ObtenerCiclo();
    }
}
